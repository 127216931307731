<template>
    <div class="box my-couponIssuance">
        <div class="member-head">
            <div style="float: left">
                <el-button type="primary" @click="batchIssueCoupon">批量发放券码</el-button>
                <router-link :to="{ path: '/member/memberManagement' }" target="_blank" class="el-button">添加成员</router-link>
            </div>
            <div style="float: right">
                <div style="display: inline-block;width:100%">
                    <el-input v-model="page.keywords" placeholder="请输入姓名/手机号码">
                        <div style="margin: 0;" slot="append">
                            <el-button class="verification" @click="memberListData">查询</el-button>
                        </div>
                    </el-input>
                </div>
            </div>
        </div>
        <div class="member-table">

            <el-table :data="tableData"  @selection-change="handleSelectionChange" ref="multipleTable" height="570">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="realname" label="姓名" ></el-table-column>
                <el-table-column prop="mobile" label="手机号"></el-table-column>
<!--                <el-table-column prop="voucher_info" label="消费券信息"></el-table-column>-->
                <el-table-column prop="date" label="操作">
                    <template slot-scope="scope">
                        <el-button class="layui-btn layui-btn-xs" @click="singleIssueCoupon(scope.row)">发放券码</el-button>
                        <el-button class="layui-btn layui-btn-xs" @click="peopleVoucherListData(scope.row)">查看记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="member-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page.page"
                    :page-size="page.pageSize"
                    :page-sizes="[10, 50, 100, 200, 300]"
                    layout="sizes, total, prev, pager, next"
                    :total="pageTotal">
            </el-pagination>
        </div>

        <el-dialog title="批量发放消费券" :visible.sync="batchIssueCouponShow" width="60%"  :before-close="closeIssueCoupon">
            <div class="batchIssueCouponBox">
                <el-row :gutter="20">
                    <el-col :span="10">
                        <div class="grid-content bg-purple">
                            <el-table :data="batchIssueCouponList" height="250" stripe border>
                                <el-table-column label="" width="60">
                                    <template scope="scope">
                                        <el-radio
                                                :label="scope.$index"
                                                v-model="currentRow"
                                                @change.native="getCurrentRow(scope.row)"
                                        ></el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="face_price" label="面值"></el-table-column>
                                <el-table-column prop="num" label="数量"></el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div class="grid-content bg-purple">
                            <el-table :data="batchIssueCouponPeople" height="250" stripe border>
                                <el-table-column prop="realname" label="姓名"></el-table-column>
                                <el-table-column prop="mobile" label="手机号"></el-table-column>
                                <el-table-column prop="date" label="操作">
                                    <template slot-scope="scope">
                                        <el-button class="layui-btn layui-btn-xs" @click="delpeople(scope.$index,scope.row)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <p style="margin-top:5px;text-align: right">所选人员总计<b>{{peopleCount}}</b>人</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top:20px;">
                    <el-col :span="24">
                        <div class="grid-content bg-purple-dark">
                            <div>
                                <span style="display: inline-block">每人发券数量：  </span>
                                <div class="coupons_money_list_input" style="display: inline-block;margin-bottom: 20px">
                                    <el-input-number v-model="couponNum" :min="1" :max="checkNum" style="width: 290px"></el-input-number>
                                </div>
                            </div>
                            <!--<div>
                                <span style="display: inline-block">&nbsp;&nbsp;&nbsp;短信动态码：  </span>
                                <div style="display: inline-block;width: 40%">
                                    <el-input v-model="mobileCode" placeholder="请输入短信验证码">
                                        <div style="margin: 0;" slot="append">
                                            <span class="verification" v-if="showTime" @click="openCode">获取动态码</span>
                                            <span v-else>{{yzmTime}}秒后重试</span>
                                        </div>
                                    </el-input>
                                </div>
                            </div>-->
                            <div slot="footer" class="dialog-footer" style="text-align: right">
                                <el-button @click="closeIssueCoupon">关 闭</el-button>
                                <el-button type="primary" @click="saveBatchIssueCoupon">确 定</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>

        <el-dialog title="发放消费券" :visible.sync="singleIssueCouponShow" width="30%" :before-close="closeSingleIssueCoupon">
            <div class="batchIssueCouponBox">
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <el-table :data="batchIssueCouponList" height="250" stripe border>
                                <el-table-column label="" width="60">
                                    <template scope="scope">
                                        <el-radio
                                                :label="scope.$index"
                                                v-model="currentRow"
                                                @change.native="getCurrentRow(scope.row)"
                                        ></el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="face_price" label="面值"></el-table-column>
                                <el-table-column prop="num" label="数量"></el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top:20px;padding-bottom: 5px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple-dark">
                            <div>
                                <span style="display: inline-block">发券数量：  </span>
                                <div class="coupons_money_list_input" style="display: inline-block;margin-bottom: 20px">
                                    <el-input-number v-model="couponNum" :min="1" :max="checkNum" style="width: 290px"></el-input-number>
                                </div>
                            </div>
                            <!--<div>
                                <span style="display: inline-block">短信动态码：  </span>
                                <div style="display: inline-block;width: 290px">
                                    <el-input v-model="mobileCode" placeholder="请输入短信验证码" >
                                        <div style="margin: 0;" slot="append">
                                            <span class="verification" v-if="showTime" @click="openCode">获取动态码</span>
                                            <span v-else>{{yzmTime}}秒后重试</span>
                                        </div>
                                    </el-input>
                                </div>
                            </div>-->
                            <div slot="footer" class="dialog-footer" style="text-align: right;margin-top:20px">
                                <el-button @click="closeSingleIssueCoupon">关 闭</el-button>
                                <el-button type="primary" @click="saveBatchIssueCoupon">确 定</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>

        <el-dialog title="输入消费券验证码" :visible.sync="activationCouponCodeShow" :before-close="closeActivationCouponCode">
            <div class="batchIssueCouponBox">
                <el-row style="margin-top:20px;padding-bottom: 20px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple-dark">
                            <div>
                                <span style="display: inline-block">短信动态码：  </span>
                                <div style="display: inline-block;width: 40%">
                                    <el-input v-model="mobileCode" placeholder="请输入短信验证码">
                                        <div style="margin: 0;" slot="append">
                                            <span class="verification" v-if="showTime" @click="openCode">获取动态码</span>
                                            <span v-else>{{yzmTime}}秒后重试</span>
                                        </div>
                                    </el-input>
                                </div>
                            </div>
                            <div slot="footer" class="dialog-footer" style="text-align: right">
                                <el-button @click="closeActivationCouponCode">关 闭</el-button>
                                <el-button type="primary" @click="saveActivationCouponCode">确 定</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>

        <el-dialog title="券码列表" :visible.sync="VoucherListshow" width="60%" >
            <div class="voucherListshow">
                <el-table :data="VoucherList"  v-loading="Voucherloading">
                    <el-table-column label="消费券号" prop="voucher_number" width="220px" ></el-table-column>
                    <el-table-column label="激活码" prop="voucher_pwd" width="110px" ></el-table-column>
                    <el-table-column label="面值" prop="face_price" width="80px" ></el-table-column>
                    <el-table-column label="发放时间" prop="create_time" width="180px" ></el-table-column>
                    <el-table-column label="激活状态" prop="receive_status_name" ></el-table-column>
                    <el-table-column label="激活人" prop="ac_member_name"  width="220px"></el-table-column>
                    <el-table-column label="激活时间" prop="receive_time" ></el-table-column>
                </el-table>
                <el-pagination
                        @current-change="handleCurrentVoucherChange"
                        :current-page.sync="VoucherPage.page"
                        :page-size="VoucherPage.page_size"
                        layout="total, prev, pager, next"
                        :total="VoucherPageTotal">
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="VoucherListshow = false">关 闭</el-button>
              </span   >
        </el-dialog>
    </div>
</template>

<script>
    import { sendSmsCode} from '@/api/member/security';
    import { mapGetters } from "vuex"
    import {
        batchSendToMember,
        getFacePrice,
        orgVoucherList,
        issueMemberList,
        couponIssuanceRecord
    } from "@/api/member/member";

    export default {
        name: "couponIssuance",
        data() {
            return {
                tableData: [],
                pageTotal: 0,
                page: {
                    page: 1,
                    page_size: 10,
                    keywords:'',
                },
                multipleSelection:[],
                singleIssueCouponShow:false,
                batchIssueCouponShow:false,
                peopleCount:0,
                checkPrice:0,
                checkNum:0,
                couponNum:1,
                currentRow:0,
                mobileCode:'',
                smsCodeKey: '',
                yzmTime: 60,
                showTime: true,
                batchIssueCouponPeople:[],
                batchIssueCouponList:[],
                VoucherPageTotal: 0,
                VoucherListshow: false,
                Voucherloading: false,
                VoucherList: [],
                VoucherPage: {
                    page: 1,
                    page_size: 10,
                    keywords: '',
                    receive_member_id: '',
                    order_no:'',
                },
                activationCouponCodeShow:false,
            }
        },
        computed: {
            ...mapGetters(["member"]),
        },
        mounted() {
            // 成员管理 列表
            this.memberListData()
        },
        methods: {
            batchIssueCoupon(){
                this.getFacePriceList()
                if(this.multipleSelection.length>0) {
                    this.batchIssueCouponShow = true
                    this.batchIssueCouponPeople = this.multipleSelection
                    this.peopleCount = this.batchIssueCouponPeople.length
                }else{
                    this.$message({
                        message: "请选择人员",
                        type: "warning"
                    })
                    return
                }
            },
            closeIssueCoupon(){
                this.batchIssueCouponShow = false
                this.activationCouponCodeShow = false
                this.batchIssueCouponPeople = this.multipleSelection = []
                this.mobileCode = ''
                this.couponNum = ''
                this.showTime = true
                this.yzmTime = 60
                this.$refs.multipleTable.clearSelection();
            },
            singleIssueCoupon(item){
                this.getFacePriceList()
                this.singleIssueCouponShow = true
                this.batchIssueCouponPeople.push(item)
            },
            closeSingleIssueCoupon(){
                this.singleIssueCouponShow = false
                this.activationCouponCodeShow = false
                this.batchIssueCouponPeople = this.multipleSelection = []
                this.$refs.multipleTable.clearSelection();
                this.mobileCode = ''
                this.couponNum = ''
                this.showTime = true
                this.yzmTime = 60
            },
            getCurrentRow(row) {
                this.checkPrice = row.face_price
                this.checkNum = row.num
            },
            delpeople(index,row) {
                this.$confirm('确定要删除该人员?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.batchIssueCouponPeople.splice(index,1);
                    this.multipleSelection = this.batchIssueCouponPeople
                    this.peopleCount = this.batchIssueCouponPeople.length

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 发送验证码
            openCode() {
                let data = {
                    mobile: this.member.mobile,
                }
                sendSmsCode(data).then(res => {
                    let data = res.data
                    this.showTime = false
                    this.smsCodeKey = data.key
                    let time = setInterval(() => {
                        this.yzmTime = this.yzmTime - 1
                        if (this.yzmTime <= 0) {
                            clearInterval(time)
                            this.yzmTime = 60
                            this.showTime = true
                        }
                    },1000)
                }).catch(e =>{
                    this.$message.error(e.message)
                })
            },
            closeActivationCouponCode(){
                this.activationCouponCodeShow = false
                this.mobileCode=''
            },
            saveActivationCouponCode(){
                // if (this.mobileCode == '') {
                //     this.$message.error("请输入短信验证码！")
                //     return
                // }
                var people_ids =[]
                this.batchIssueCouponPeople.filter( item => {people_ids.push(item.member_id)})
                let issuedCount = parseInt(people_ids.length) * this.couponNum
                var data = {
                    member_ids:people_ids,
                    face_price: this.checkPrice,
                    num: this.couponNum,
                    key: this.smsCodeKey,
                    auth_code: this.mobileCode
                }
                batchSendToMember(data)
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success("发放成功!")
                            this.closeIssueCoupon()
                            this.closeSingleIssueCoupon()
                            this.memberListData()
                        } else {
                            this.flag = false
                            this.$message({ message: res.message, type: "warning" })
                        }
                    })
                    .catch(err => {
                        this.flag = false
                        this.$message.error(err.message)
                    })
            },
            saveBatchIssueCoupon(){
                var people_ids =[]
                this.batchIssueCouponPeople.filter( item => {people_ids.push(item.member_id)})
                let issuedCount = parseInt(people_ids.length) * this.couponNum
                if (people_ids.length == 0) {
                    this.$message.error("请选择人员！")
                    return
                }
                if (issuedCount > this.checkNum) {
                    this.$message.error("当前所选面值的消费券数量不足！")
                    return
                }

                this.activationCouponCodeShow = false
                this.saveActivationCouponCode();
            },
            getFacePriceList(){
                getFacePrice().then(res => {
                    this.batchIssueCouponList =  res.data
                    if(this.checkPrice == 0) {
                        this.checkPrice = res.data[0].face_price
                        this.checkNum = res.data[0].num
                    }
                })
            },
            //查看记录
            peopleVoucherListData(item) {
                this.VoucherListshow = true
                this.Voucherloading = true
                /* this.VoucherPage.order_no = item ? item.order_no : this.VoucherPage.order_no
                 this.VoucherPage.receive_member_id = item ? item.member_id : this.VoucherPage.receive_member_id
                 orgVoucherList(this.VoucherPage).then(res =>{
                     this.Voucherloading = false
                     let data = res.data
                     this.VoucherList = data.list
                     this.VoucherPageTotal = data.count
                 })*/

                this.VoucherPage.keywords = item.mobile
                couponIssuanceRecord(this.VoucherPage).then(res =>{
                    this.Voucherloading = false
                    let data = res.data
                    this.VoucherList = data.list
                    this.VoucherPageTotal = data.count
                })
            },
            handleCurrentVoucherChange(val) {
                this.VoucherPage.page = val
                this.peopleVoucherListData()
            },
            // 成员管理 列表
            memberListData() {
                issueMemberList(this.page).then(res => {
                    let data = res.data
                    this.tableData =  data.list
                    this.pageTotal =  data.count
                })
            },
            handleSizeChange(val) {
                this.page.page_size = val
                this.memberListData()
            },
            handleCurrentChange(val) {
                this.page.page = val
                this.memberListData()
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        }
    }
</script>

<style type="text/scss" lang="scss">
    .layui-btn-xs {
        height: 22px;
        line-height: 22px;
        padding: 0 5px;
        font-size: 12px;
        margin-bottom: 5px;
    }
    .my-couponIssuance .el-dialog__body{
       padding:0px 20px 20px;
    }
    .batchIssueCouponBox .el-table th, .batchIssueCouponBox .el-table td{
        padding: 6px 0px;
        text-align: center;
    }
    .batchIssueCouponBox .el-table .el-radio__label{
        display: none;
    }
</style>
<style scoped type="text/scss" lang="scss">
    .my-couponIssuance {
        background: #ffffff;
        padding: 20px;
    }
    .logData{
        height: 400px;
        overflow: auto;
        &_conter{
            display: flex;
            justify-content: space-between;
        }
        &_list{
            margin-bottom: 10px;
        }
    }
    .addData{
        width: 500px;
    }
    .member-page{
        ::v-deep .el-pagination {
            margin-top: 10px;
            text-align: center;
        }
    }
    .member-head {
        text-align: left;

        &-load {
            display: inline-block;
            margin: 0 10px;
        }
    }

    .member-table {
        margin-top: 10px;
    }
</style>
